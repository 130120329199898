import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView";
// import Login from "../views/loginLayout";
import navigationGuard from "./navigation-guard";
import i18n from "@/plugins/i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    redirect: {
      name: "Dashboard",
      paarams: { lang: `${i18n.locale}` },
    },
  },
  {
    path: "/:lang",
    props: true,
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      // {
      //   path: "/",
      //   component: HomeView,
      //   children: [
      //     {
      //       path: "",
      //       component: () => import("@/views/pageLayout"),
      //       children: [
      //         {
      //           path: "",
      //           name: "Landing",
      //           component: () => import("@/views/landing"),
      //           // meta: {
      //           //   title:
      //           //     "South Sudan Mining Journal - News and Analysis on Mining in South Sudan",
      //           // },
      //         },
      //         {
      //           path: "news",
      //           name: "newsView",
      //           component: () => import("@/views/pages/newsView"),
      //           meta: {
      //             title: "News and Articles",
      //           },
      //         },
      //         {
      //           path: "content/",
      //           name: "Content Page",
      //           component: () => import("@/views/contentWrapper.vue"),
      //           props: true,
      //         },
      //         {
      //           path: "researches-and-journals",
      //           name: "Researches & Journals",
      //           component: () => import("@/views/pages/articles"),
      //           meta: {
      //             title: "Researches and Journals",
      //           },
      //         },
      //         {
      //           path: "research-journal-details/:id/:title/",
      //           name: "Journal Details",
      //           component: () => import("@/components/page/research"),
      //           props: true,
      //           meta: {
      //             title: "Research Details",
      //           },
      //         },
      //         {
      //           path: "careers",
      //           name: "Careers",
      //           component: () => import("@/views/pages/jobs"),
      //           meta: {
      //             title: "Careers",
      //           },
      //         },
      //         {
      //           path: "media",
      //           name: "Media",
      //           component: () => import("@/views/pages/media"),
      //           meta: {
      //             title: "Media",
      //           },
      //         },
      //         {
      //           path: "album/:id/:title/",
      //           name: "Album Details",
      //           component: () => import("@/components/page/album"),
      //           props: true,
      //           meta: {
      //             title: "Research Details",
      //           },
      //         },
      //         {
      //           path: "market-view",
      //           name: "marketView",
      //           component: () => import("@/views/pages/marketView"),
      //           meta: {
      //             title: "Market Commodities",
      //           },
      //         },
      //         {
      //           path: "posts/:filter", //'news' initially, changed by Justin
      //           props: true,
      //           name: "All News",
      //           component: () => import("@/views/pages/news"),
      //           meta: {
      //             title: "News & Articles",
      //           },
      //         },
      //         {
      //           path: "about-us",
      //           name: "About Us",
      //           component: () => import("@/views/pages/about"),
      //           meta: {
      //             title: "About",
      //           },
      //         },
      //         {
      //           path: "projects",
      //           name: "Projects",
      //           component: () => import("@/views/pages/projects"),
      //         },
      //         {
      //           path: "events",
      //           name: "events",
      //           component: () => import("@/views/pages/projects"),
      //         },
      //         {
      //           path: "event/:id/:title",
      //           name: "Event Details",
      //           component: () => import("@/components/page/event"),
      //           props: true,
      //         },
      //         {
      //           path: "partners",
      //           name: "All Partners",
      //           component: () => import("@/views/pages/partners"),
      //         },
      //         {
      //           path: "partner/:id/:title",
      //           name: "Partner Details",
      //           component: () => import("@/components/page/partner"),
      //           props: true,
      //         },
      //         {
      //           path: "products",
      //           name: "Activities",
      //           component: () => import("@/components/page/products"),
      //           meta: {
      //             title: "Products",
      //           },
      //         },
      //         {
      //           path: "product/:id",
      //           name: "Product Details",
      //           component: () => import("@/components/page/product"),
      //           props: true,
      //           meta: {
      //             title: "Product Details",
      //           },
      //         },
      //         {
      //           path: "post/:title/:id",
      //           name: "Article Details",
      //           component: () => import("@/components/page/post"),
      //           props: true,
      //           meta: {
      //             title: "Post Details",
      //           },
      //         },
      //         {
      //           path: "all-posts/:publisher",
      //           name: "Publisher Posts",
      //           component: () => import("@/components/page/newsByPublisher"),
      //           props: true,
      //         },
      //         {
      //           path: "caareer/:id",
      //           name: "Job Details",
      //           component: () => import("@/components/page/job"),
      //           props: true,
      //           meta: {
      //             title: "Job Details",
      //           },
      //         },
      //         {
      //           path: "mining-cadastre-portals",
      //           name: "Mining Cadastre Portals",
      //           component: () => import("@/components/page/geologicalPortals"),
      //           meta: {
      //             title: "Mining Cadastre Portal",
      //           },
      //         },
      //         {
      //           path: "reports-and-maps",
      //           name: "Reports and Maps",
      //           component: () => import("@/views/pages/reports-maps.vue"),
      //           meta: {
      //             title: "Reports and Maps",
      //           },
      //         },
      //         {
      //           path: "mining-activities",
      //           name: "Mining Activities",
      //           component: () => import("@/components/page/miningActivities"),
      //         },
      //         {
      //           path: "profile",
      //           component: () => import("@/views/profile"),
      //           children: [
      //             {
      //               path: "",
      //               name: "My Dashboard",
      //               component: () => import("@/views/profile/dashboard"),
      //             },
      //             {
      //               path: "my-news",
      //               name: "My News",
      //               component: () => import("@/views/profile/news"),
      //             },
      //             {
      //               path: "my-researches",
      //               name: "My Researches",
      //               component: () => import("@/views/profile/researches"),
      //             },
      //             {
      //               path: "my-events",
      //               name: "My Events",
      //               component: () => import("@/views/profile/events"),
      //             },
      //             {
      //               path: "my-details",
      //               name: "My Details",
      //               component: () => import("@/views/profile/details"),
      //             },
      //           ],
      //         },
      //         {
      //           path: "privacy-policy",
      //           name: "Privacy",
      //           props: true,
      //           component: () => import("@/views/pages/privacy"),
      //         },
      //         {
      //           path: "investor-guide",
      //           name: "Investor Guide Doc",
      //           props: true,
      //           component: () => import("@/views/pages/investor-guide"),
      //         },
      //         {
      //           path: "editorial-team",
      //           name: "Editorial Team",
      //           component: () => import("@/views/pages/team"),
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        // path: "admin-panel",
        path: "/",
        component: () => import("@/views/admin/adminPanel"),
        beforeEnter: (to, from, next) => {
          navigationGuard.goTo(to, from, next);
        },
        children: [
          {
            path: "",
            redirect: "dashboard",
          },
          {
            path: "dashboard",
            name: "Dashboard",
            component: () => import("@/components/admin/drawerLinks/dashboard"),
          },
          {
            path: "products",
            name: "Products",
            component: () => import("@/components/admin/drawerLinks/products"),
          },
          {
            path: "jobs",
            name: "Jobs",
            component: () => import("@/components/admin/drawerLinks/jobs"),
          },
          {
            path: "events",
            name: "Events",
            component: () => import("@/components/admin/drawerLinks/events"),
          },
          {
            path: "posts",
            redirect: { name: "Posts" },
            component: () => import("@/components/admin/drawerLinks/news"),
            children: [
              {
                path: "list-posts",
                name: "Posts",
                component: () =>
                  import("@/components/admin/drawerLinks/news/listNews"),
              },
              {
                path: "create-post",
                name: "Create Post",
                component: () => import("@/views/admin/news/createNews"),
              },
              {
                path: "details/:newsId",
                component: () => import("@/views/admin/news/details"),
                name: "Post Details",
                props: true,
              },
            ],
          },
          {
            path: "researches",
            redirect: { name: "Research & Journals" },
            component: () =>
              import("@/components/admin/drawerLinks/researches"),
            children: [
              {
                path: "list-researches",
                name: "Research & Journals",
                component: () =>
                  import(
                    "@/components/admin/drawerLinks/researches/listResearches"
                  ),
              },
              {
                path: "create-research",
                name: "Create Research",
                component: () =>
                  import("@/views/admin/researches/createResearch"),
              },
              {
                path: "details/:researchId",
                component: () => import("@/views/admin/researches/details"),
                name: "Research Details",
                props: true,
              },
            ],
          },
          {
            path: "settings",
            name: "Settings",
            component: () => import("@/views/admin/pages/settings"),
          },
          {
            path: "job-types",
            name: "Job Types",
            component: () => import("@/components/admin/settings/jobTypes"),
          },
          {
            path: "event-types",
            name: "Event Types",
            component: () => import("@/components/admin/settings/eventTypes"),
          },
          {
            path: "post-types",
            name: "Post Types",
            component: () => import("@/components/admin/settings/newsTypes"),
          },
          {
            path: "map-categories",
            name: "Map Categories",
            component: () =>
              import("@/components/admin/settings/map-categories"),
          },
          {
            path: "policies",
            name: "All Policies",
            component: () => import("@/components/admin/settings/policies"),
          },
          {
            path: "investor-guide",
            name: "Investor Guide",
            component: () =>
              import("@/components/admin/settings/investor-guide"),
          },
          {
            path: "categories",
            name: "Categories",
            component: () => import("@/components/admin/settings/categories"),
          },
          {
            path: "subCategories",
            name: "Sub Categories",
            component: () =>
              import("@/components/admin/settings/subCategories"),
          },
          {
            path: "sources",
            name: "Sources",
            component: () => import("@/components/admin/settings/sources"),
          },
          {
            path: "partners",
            name: "Partners",
            component: () => import("@/components/admin/settings/partners"),
          },
          {
            path: "users",
            name: "Users",
            component: () => import("@/components/admin/drawerLinks/users"),
          },
          {
            path: "subscribers",
            name: "Subscribers",
            component: () =>
              import("@/components/admin/drawerLinks/subscribers"),
          },
          {
            path: "professions",
            name: "Professions",
            component: () =>
              import("@/components/admin/drawerLinks/professions"),
          },
          {
            path: "contact",
            name: "Contacts",
            component: () =>
              import("@/components/admin/drawerLinks/contact-us"),
          },
          {
            path: "commodities",
            name: "Commodities",
            component: () =>
              import("@/components/admin/drawerLinks/commodities"),
          },
          {
            path: "videos",
            name: "Youtube Videos",
            component: () => import("@/components/admin/drawerLinks/videos"),
          },
          {
            path: "gallery",
            name: "Gallery",
            component: () => import("@/components/admin/drawerLinks/gallery"),
          },
          {
            path: "profile",
            name: "Profile",
            component: () => import("@/components/admin/drawerLinks/profile"),
          },
          {
            path: "team",
            name: "Team",
            component: () => import("@/components/admin/drawerLinks/team"),
          },
          {
            path: "maps",
            name: "Reports & Maps",
            component: () => import("@/views/admin/maps"),
          },
          {
            path: "paystack",
            name: "Paystack",
            component: () => import("@/views/admin/paystack"),
          },
        ],
      },
      {
        path: "auth",
        component: () => import("@/views/auth"),
        children: [
          {
            path: "",
            name: "Login",
            component: () => import("@/views/auth/login"),
          },
          {
            path: "signup",
            name: "Signup",
            component: () => import("@/views/auth/signup"),
          },
          {
            path: "reset-password",
            name: "Reset Password",
            component: () => import("@/views/auth/reset-password"),
          },
          {
            path: "new-password/:token",
            props: true,
            name: "New Password",
            component: () => import("@/views/auth/new-password"),
          },
          {
            path: "email-verification/",
            // props: true,
            name: "Email Verification",
            component: () => import("@/views/auth/email-verification"),
          },
        ],
      },
      {
        path: "admin-verification",
        name: "Admin Verification",
        component: () => import("@/views/admin-verification"),
        props: true,
      },
      {
        path: "*",
        name: "Error",
        component: () => import("@/views/route-error"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.afterEach((to, from) => {
  // to and from are both route objects.
  if (from.name == "Login") {
    store.commit("SET_ACTION_REDIRECT", null);
  }
});
// router.afterEach((to, from) => {
//   Vue.nextTick(() => {
//     if (to.params?.title) {
//       let title = to.params?.title.replaceAll("-", " ");
//       // console.log(title, to.params)
//       if (!to.meta?.title) {
//         document.title = `${to.name} | ${title}`;
//       } else {
//         document.title = `${to.meta?.title} | ${title}`;
//         // to.meta.content = title;
//       }
//     } else {
//       document.title =
//         to.meta?.title ||
//         "South Sudan Mining Journal - News and Analysis on Mining in South Sudan";
//     }
//   });
// });
// ...

// ...
export default router;
